<template>
  <section class="events">
    <div class="events__day container">
      <h1>Pre-Registration Day</h1>
      <h2>Tuesday, January 21st</h2>
    </div>
    <div class="events__odd">
      <div class="events__content container">
        <div class="events__content--image">
          <img src="@/assets/entertainment/opening-hh.jpg" alt="Cheers!">
        </div>
        <div class="events__content--info">
          <h1 class="events__title">
            Welcome Happy Hour
          </h1>
          <h3 class="events__date">
            East Veranda - Condado Vanderbilt
          </h3>
          <p class="events__description">
            5:00 PM to 7:00 PM
          </p>
        </div>
      </div>
    </div>
    <div class="events__even">
      <div class="events__day container">
        <h1>Day One</h1>
        <h2>Wednesday, January 22nd</h2>
      </div>
      <div class="events__content container">
        <div class="events__content--image">
          <img src="@/assets/entertainment/happyhr.jpeg" alt="Tequila Restaurant Cheers!">
        </div>
        <div class="events__content--info">
          <h1 class="events__title">
            Happy Hour
          </h1>
          <h3 class="events__date">
            Tacos & Tequila - Condado Vanderbilt
          </h3>
          <p class="events__description">
            5:30 PM to 7:00 PM
          </p>
        </div>
      </div>
    </div>
    <div class="events__odd">
      <div class="events__content container">
        <div class="events__content--image">
          <img src="@/assets/entertainment/monastery.jpeg" alt="Monastery Art Suites">
        </div>
        <div class="events__content--info">
          <h1 class="events__title">
            The Money Ball by The Money Channel
          </h1>
          <p class="events__description">
            7:00 PM to 11:00 PM <br>
            <a href="https://maps.app.goo.gl/nRKnhb7zfRuFN9o4A" target="_blank">Monasterio, Old San Juan</a> <br> 250 Calle del Cristo, San Juan, 00901, Puerto Rico
          </p>
        </div>
      </div>
    </div>
    <div class="events__even">
      <div class="events__day container">
        <h1>Day Two</h1>
        <h2>Thursday, January 23rd</h2>
      </div>
      <div class="events__content container">
        <div class="events__content--image">
          <img src="@/assets/entertainment/hh-tequila.jpeg" alt="Happy Hour Drinks">
        </div>
        <div class="events__content--info">
          <h1 class="events__title">
            Closing Cocktail Party
          </h1>
          <h3 class="events__date">
            West Veranda - Condado Vanderbilt
          </h3>
          <p class="events__description">
            5:00 PM to 8:00 PM
          </p>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Entertainment Events',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/entertainment/events.scss";
</style>