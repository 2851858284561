<template>
  <section class="hero">
    <video class="hero__background-video" autoplay muted loop>
      <source src="@/assets/hero/bg.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div class="hero__overlay"></div>
    <div class="hero__content container">
      <div class="hero__content--info">
        <h1 class="hero__title">
          Sequire Investor Summit
        </h1>
        <h2 class="hero__date">
          January 21st - 23rd, 2025
        </h2>
        <h3 class="hero__location">
          Condado Vanderbilt, Puerto Rico
        </h3>
        <a href="https://www.condadovanderbilt.com/specials?ds_rl=1250973&gclid=EAIaIQobChMIydqAzKrsgQMVrCqzAB1C0gl6EAAYASAAEgLIhfD_BwE&gclsrc=aw.ds#/booking/step-1?data=('h8hd!'condado-new'~a9dt3fs.~r8at!2~cn!0~cg.~al6po3gp!'012125SRAX20'~rn.)7ry3rk3re.~rr*)7ax!0~cy3ds!('p9ls3as3st*)~my6s9c9ne*)*!null.4%5D3*~4!%5B6!false~7%5D~8s4('9e3%01987643.*_" target="_blank">
          <button class="hero__btn">
            Book Now
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "../styles/components/hero.scss";
  </style>