<template>
  <section class="views">
    <div class="views__content container">
      <div class="views__card">
        <h1 class="views__title">
          Stunning, Panoramic Ocean Views
        </h1>
        <p class="views__description">
          Immerse yourself in a space where sophisticated design flows seamlessly into the natural beauty of the Caribbean.
          Expansive views stretch out before you, framed by elegant French doors that open to your private terrace or balcony.
          This is more than just a room—it’s your personal slice of paradise.
        </p>
        <a href="/#book" class="views__btn btn">
          <button class="button book">Book Now</button>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/venue/views.scss';
</style>

<script>

export default {
  name: 'Breathtaking Views',
};
</script>