<template>
  <section class="services">
    <div class="services__butler">
      <div class="services__content container">
        <div class="services__content--image">
          <img src="@/assets/venue/butler.png" alt="Pool & Drinks">
        </div>
        <div class="services__content--info">
          <h1 class="services__title">
            Tailored Butler Service
          </h1>
          <p class="services__description">
            Every guest at the Condado Vanderbilt enjoys twice-daily housekeeping and comprehensive
            concierge services. However, those staying in a Commodore Oceanfront Suite can elevate their
            experience with the added luxury of a personal butler. From unpacking and organizing your
            belongings to curating a personalized itinerary and fulfilling special requests, your butler
            ensures a seamless and tailored stay.
          </p>
          <a href="https://www.condadovanderbilt.com/"
              target="_blank" class="services__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
    <div class="services__spa">
      <div class="services__content container">
        <div class="services__content--image">
          <img src="@/assets/venue/spa.png" alt="Spa">
        </div>
        <div class="services__content--info">
          <h1 class="services__title">
            Revitalize at The Spa
          </h1>
          <p class="services__description">
            Our Spa offers unique treatments designed to soothe, rejuvenate, and promote mind-body harmony.
            Experience relaxation and renewal with our personalized wellness therapies and premium products.
          </p>
          <a href="https://www.condadovanderbilt.com/spa-and-fitness/"
              target="_blank" class="services__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Services',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/services.scss";
</style>