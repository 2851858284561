<template>
  <section class="stay">
    <div class="stay__content container">
      <div class="stay__content--image">
        <img src="@/assets/hotel/hotel-stay.png" alt="Hotel Room">
      </div>
      <div class="stay__content--info">
        <h1 class="stay__title">
          Exquisite getaway
        </h1>
        <p class="stay__description">
          Step into a world of unrivaled elegance with our exceptional accommodations, crafted for
          those who seek the extraordinary. Your room is a sanctuary, complete with 300-thread count
          Rivoita Carmignani linens, cloud-like bedding, and a spa-inspired bath featuring sleek marble
          showers and elegant stone vanities. Here, luxury isn't just a word—it’s a feeling you’ll experience at every turn.
        </p>
        <a href="/#book" class="stay__btn btn">
          <button class="button book">Book Now</button>
        </a>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Stay',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/stay.scss";
</style>