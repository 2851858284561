<template>
  <section class="presenters">
    <div class="presenters__content container">
      <h1 class="presenters__title">
        Presenting Companies
      </h1>
      <!--<a href="https://schedule.puertorico24.srax.com/"
        target="_blank" class="agenda-btn">
          2024 Agenda
        </a> -->
      <div class="presenters__cards">
        <div class="presenters__item" v-for="(logo, index) in logos" :key="index" :class="logo.class">
          <div class="presenters__item--logo">
            <img :src="logo.url" :alt="logo.name">
          </div>
          <div class="presenters__item--info">
            <div class="titles">
              <h2>{{ logo.name }}</h2>
              <p>{{ logo.market }}<template v-if="logo.ticker">:</template> {{ logo.ticker }} </p>
            </div>
            <a v-if="logo.path" :href="logo.path" target="_blank">View Company</a>
          </div>
        </div>
        <h2 class="presenters__subtitle">& more to be announced..</h2>
      </div>
    </div>
  </section>
  <sponsors />
</template>

<style lang="scss" scoped>
  @import '@/styles/components/presenters.scss';
</style>

<script>
import sponsors from '@/components/sponsors.vue';

export default {
  name: 'presenters',
  components: {
    sponsors
  },
  data() {
    return {
      logos: [
        { url: require('../assets/presenters/ryvyl.png'),
          name: 'Ryvyl Inc', class: 'ryvyl',
          market: 'NASDAQ', ticker: 'RVYL',
          path: 'https://www.ryvyl.com/'
        },
        { url: require('../assets/presenters/tcbio.png'),
          name: 'TC BioPharm Ltd', class: 'tcbiopharm',
          market: 'NASDAQ', ticker: 'TCBP',
          path: 'https://tcbiopharm.com/'
        },
        { url: require('../assets/presenters/soligenix.png'),
          name: 'Soligenix, Inc. Common Stock', class: 'soligenix',
          market: 'NASDAQ', ticker: 'SNGX',
          path: 'https://www.soligenix.com/'
        },
        { url: require('../assets/presenters/lisata.png'),
          name: 'Lisata Therapeutics Inc', class: 'lisata',
          market: 'NASDAQ', ticker: 'LSTA',
          path: 'https://www.lisata.com/'
        },
        { url: require('../assets/presenters/onar.svg'),
          name: 'ONAR', class: 'onar',
          market: 'OTCQB', ticker: 'RELT',
          path: 'https://www.onar.com/'
        },
        { url: require('../assets/presenters/lightpath.png'),
          name: 'Lightpath', class: 'lightpath',
          market: 'NASDAQ', ticker: 'LPTH',
          path: 'https://lightpathfiber.com/'
        },
        { url: require('../assets/presenters/pantheon.svg'),
          name: 'Pantheon Resources', class: 'pantheon',
          market: 'LON', ticker: 'PANR',
          path: 'https://www.pantheonresources.com/'
        },
        { url: require('../assets/presenters/geodrill.png'),
          name: 'Geodrill', class: 'geodrill',
          market: 'TSX', ticker: 'GEO',
          path: 'https://www.geodrill.ltd/'
        },
        { url: require('../assets/presenters/eckard.svg'),
          name: 'Eckard Enterprises, LLC', class: 'eckard',
          market: 'Private',
          path: 'https://eckardenterprises.com/'
        },
        { url: require('../assets/presenters/ipa.png'),
          name: 'ImmunoPrecise Antibodies', class: 'ipa',
          market: 'NASDAQ', ticker: 'IPA',
          path: 'https://www.ipatherapeutics.com/'
        },
        { url: require('../assets/presenters/ophc.png'),
          name: 'Optimum Bank', class: 'ophc',
          market: 'NASDAQ', ticker: 'OPHC',
          path: 'https://www.optimumbank.com/'
        },
        { url: require('../assets/presenters/strawberry.png'),
          name: 'Strawberry Fields REIT, Inc.', class: 'strawberry',
          market: 'NYSE', ticker: 'STRW',
          path: 'https://www.strawberryfieldsreit.com/'
        },
      ],
    };
  },
};
</script>