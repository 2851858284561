<template>
  <section class="culinary">
    <div class="culinary__content">
      <div class="culinary__card">
        <h1 class="culinary__title">
          Exquisite Culinary Experiences Await
        </h1>
        <p class="culinary__description">
          Savor an extraordinary culinary experience at the Condado Vanderbilt Hotel, where every meal tells a story.
          From sophisticated lounges and iconic bars to relaxed outdoor spaces and elegant dining rooms, our diverse
          culinary offerings cater to every craving, ensuring a feast for both the senses and the soul.
        </p>
        <a href="https://www.condadovanderbilt.com/dine/"
            target="_blank" class="culinary__btn btn">
          <button class="button">Learn More</button>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/venue/culinary.scss';
</style>

<script>

export default {
  name: 'Breathtaking culinary',
};
</script>