<template>
  <section class="companypass">
    <div class="companypass__content container">
      <div class="companypass__content--image">
        <img src="@/assets/event/3.png" alt="Event in Salon">
      </div>
      <div class="companypass__content--info">
        <h1 class="companypass__title">
          Register now to lock in your preferred time and date at this year's highly anticipated event, returning for its third edition.
          Don't miss this exclusive chance to showcase your innovations to an elite audience. Act quickly—early bird slots are limited!
        </h1>
        <a href="https://buy.stripe.com/7sI6qSfL02xq3ZudQW" target="_blank" class="companypass__btn btn">
          <button class="button">Get Your Company Pass</button>
        </a>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Company Pass',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/companypass.scss";
</style>